import React from 'react'
import Dashboard from './dashboard'
import Navbar from './Navbar'

function main() {
  return (
    <div>
        <Navbar/>
        <Dashboard/>
    </div>
  )
}

export default main